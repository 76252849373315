import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import {Route, BrowserRouter, Switch} from 'react-router-dom';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CssBaseline from '@mui/material/CssBaseline';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import CarrotFoamTree from "./CarrotFoamTree";
import {Autocomplete, createFilterOptions, MenuItem, Select, TextField, Link} from "@mui/material";
import all_tags from './all_tags';
import Graph from "./Graph";
import Barplot from "./Barplot";
import Home from "./Home";

const drawerWidth = 500;

function capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
    open?: boolean;
}>(({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -drawerWidth,
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: 0,
    }),
}));

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
    background: 'white',
    color: 'black',
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: drawerWidth,
    }),
}));

// @ts-ignore
const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
}));

export default function PersistentDrawerRight() {
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);
    const [currentTag, setCurrentTag] = React.useState('Most popular StackOverflow tags');
    const [search, setSearch] = React.useState('');
    const [limit, setLimit] = React.useState(50);
    const [dataCount, setDataCount] = React.useState(0);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const filterOptions = createFilterOptions({
        matchFrom: 'any',
        limit: 200
    });

    return (
        <BrowserRouter>
            <Switch>
                <Route path="/foamtree">
                    <Box sx={{ display: 'flex' }}>
                        <CssBaseline />
                        <AppBar position="fixed" open={open}>
                            <Toolbar>
                                <Typography variant="h6" noWrap sx={{ flexGrow: 1 }} component="div">
                                    {currentTag}
                                </Typography>
                                {/*<IconButton
                                color="inherit"
                                aria-label="open drawer"
                                edge="end"
                                onClick={handleDrawerOpen}
                                sx={{ ...(open && { display: 'none' }) }}
                            >
                                <MenuIcon />
                            </IconButton>*/}
                                <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    filterOptions={filterOptions}
                                    options={all_tags.map(t => ({ label: t }))}
                                    sx={{ width: 300 }}
                                    style={{ position: 'relative', top: 0 }}
                                    renderInput={(params) =>
                                        <TextField {...params} label="Stackoverflow tag" />
                                    }
                                    onChange={(e, val: any) => {
                                        const value = val.label;
                                        document.getElementById('foam-tree-element-parent')!.innerHTML =
                                            '<div id="foam-tree-element" style="height: calc(100vh - 64px); width: 100vw"/>';
                                        setCurrentTag(capitalizeFirstLetter(value));
                                        setSearch(value);
                                    }}
                                />
                            </Toolbar>
                        </AppBar>
                        <Main style={{ padding: 0 }} open={open}>
                            <DrawerHeader />
                            <CarrotFoamTree setCurrentTag={setCurrentTag} search={search}/>
                        </Main>
                        <Drawer
                            sx={{
                                width: drawerWidth,
                                flexShrink: 0,
                                '& .MuiDrawer-paper': {
                                    width: drawerWidth,
                                },
                            }}
                            variant="persistent"
                            anchor="right"
                            open={open}
                        >
                            <DrawerHeader>
                                <IconButton onClick={handleDrawerClose}>
                                    {theme.direction === 'rtl' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                                </IconButton>
                            </DrawerHeader>
                            <Divider />
                        </Drawer>
                    </Box>
                </Route>
                <Route path="/graph">
                    <Box sx={{ display: 'flex' }}>
                        <CssBaseline />
                        <AppBar position="fixed" open={open}>
                            <Toolbar>
                                <Typography variant="h6" noWrap sx={{ flexGrow: 1 }} component="div">
                                    {currentTag}
                                </Typography>
                                {/*<IconButton
                                color="inherit"
                                aria-label="open drawer"
                                edge="end"
                                onClick={handleDrawerOpen}
                                sx={{ ...(open && { display: 'none' }) }}
                            >
                                <MenuIcon />
                            </IconButton>*/}
                                <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    filterOptions={filterOptions}
                                    options={all_tags.map(t => ({ label: t }))}
                                    sx={{ width: 300 }}
                                    style={{ position: 'relative', top: 0 }}
                                    renderInput={(params) =>
                                        <TextField {...params} label="Stackoverflow tag" />
                                    }
                                    onChange={(e, val: any) => {
                                        const value = val.label;
                                        setCurrentTag(capitalizeFirstLetter(value));
                                        setSearch(value);
                                    }}
                                />
                            </Toolbar>
                        </AppBar>
                        <Main style={{ padding: 0 }} open={open}>
                            <DrawerHeader />
                            <Graph setCurrentTag={setCurrentTag} setSearch={setSearch} search={search}/>
                        </Main>
                        <Drawer
                            sx={{
                                width: drawerWidth,
                                flexShrink: 0,
                                '& .MuiDrawer-paper': {
                                    width: drawerWidth,
                                },
                            }}
                            variant="persistent"
                            anchor="right"
                            open={open}
                        >
                            <DrawerHeader>
                                <IconButton onClick={handleDrawerClose}>
                                    {theme.direction === 'rtl' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                                </IconButton>
                            </DrawerHeader>
                            <Divider />
                        </Drawer>
                    </Box>
                </Route>
                <Route path="/barplot">
                    <AppBar position="fixed" open={open}>
                        <Toolbar>
                            <Typography variant="h6" noWrap sx={{ flexGrow: 1 }} component="div">
                                {search != '' ? `Number of StackOverflow relations for tag "${currentTag}"` : currentTag }
                                {/*  | {limit > 0 ? 'Top ' + limit : 'All'} */}
                            </Typography>
                            <Select
                                value={limit}
                                onChange={(e) => setLimit(e.target.value as number)}
                                style={{ marginRight: 5 }}
                            >
                                <MenuItem value={10}>Top 10</MenuItem>
                                <MenuItem value={20}>Top 20</MenuItem>
                                <MenuItem value={50}>Top 50</MenuItem>
                                <MenuItem value={100} disabled={search != ''}>Top 100</MenuItem>
                            </Select>
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                filterOptions={filterOptions}
                                options={all_tags.map(t => ({ label: t }))}
                                sx={{ width: 300 }}
                                style={{ position: 'relative', top: 0 }}
                                value={search != ''? currentTag : null}
                                renderInput={(params) =>
                                    <TextField {...params} label="Stackoverflow tag" />
                                }
                                onChange={(e, val: any) => {
                                    if (val) {
                                        const value = val.label;
                                        setCurrentTag(capitalizeFirstLetter(value));
                                        setSearch(value);
                                    }
                                }}
                            />
                        </Toolbar>
                    </AppBar>
                    <Barplot setCurrentTag={setCurrentTag} setSearch={setSearch} search={search} limit={limit} setDataCount={setDataCount}/>
                </Route>
                <Route path="/">
                    <Home/>
                </Route>
            </Switch>
            <Typography color="text.secondary" align="center" position="fixed" style={{ bottom: 0, width: '100%', background: 'white' }}>
                Data source: <Link color="inherit" href="https://stackoverflow.com/" target="_blank">stackoverflow.com</Link>, 2021
            </Typography>
        </BrowserRouter>
    );
}
