import React, { useState, useEffect } from 'react';
import axios from "axios";
import * as d3 from "d3";
import BarchartWrapper from "./BarchartWrapper";

function capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export default React.memo(function Barplot(props: { setCurrentTag: Function, setSearch: Function, setDataCount: Function, search: string, limit: number }) {

    const [data, setData] = useState(null);
    const root = 'https://www.stackoverflow-tags.com/';

    var generateFromBackend = (function () {
        // @ts-ignore
        return async  (tag) => {
            var url = tag === '' ? root+'popular_tags.json' : (root+'all_tags_details/' + tag.replace(/#/g, '##') + '.json');
            var request_result = (await axios.get(url)).data;
            if(tag !== '') request_result = request_result.items;
            var result: { name: string; children: Array<any>; value: number;}[] = [];
            request_result.forEach(function (item: any, index: any) {
                if(tag != (tag === '' ? item.tag2 : item.name)) {
                  result.push({
                    name: tag === '' ? item.tag2 : item.name,
                    value: tag === '' ? item.sum_matching_total : item.count,
                    children: [{"name": tag === '' ? item.tag2 : item.name, "value": 0}]
                  });
                }
            });
            props.setDataCount(result.length);
            if(props.limit > 0) result = result.slice(0, props.limit);
            console.log(result);
            return {
              "name": tag,
              "children": result
            };
        }
    })();

    useEffect(() => {
      const element = document.getElementById("bar-chart-element");
      const getData = async () => {
        const response = await generateFromBackend(props.search);
        // @ts-ignore
        setData(response);
      };
      getData();
    }, [props.search, props.limit]); // Run only once

    // @ts-ignore
    return (
        <div id="bar-chart-element-parent">
          <div
            id="bar-chart-element"
            style={{
              marginTop: "80px",
              height: "calc(100vh - 110px)",
              width: "100vw",
            }}
          >
            {data ? (
              <BarchartWrapper data={data} setCurrentTag={props.setCurrentTag} setSearch={props.setSearch} limit={props.limit} />
            ) : (
              <div>Loading data</div>
            )}
          </div>
        </div>
      );
});
