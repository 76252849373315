import React, { useState, useEffect } from 'react';
import axios from "axios";
const FoamTreeObject = require('@carrotsearch/foamtree') as any;
const { FoamTree } = FoamTreeObject;

function capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export default React.memo(function CarrotFoamTree(props: { setCurrentTag: Function, search: string }) {
    let foamtree: { set: (arg0: { stacking?: string; maxGroupLabelLevelsDrawn?: number; maxGroupLevelsDrawn?: number; dataObject?: { groups: { label: any; expandable: boolean; weight: any; groups: never[]; }[]; }; }) => void; } | null = null;
    useEffect(() => { (async function() {
        const element = document.getElementById('foam-tree-element');
        // Generates some random groups
        var generate = (function () {
            var number = 0;

            // @ts-ignore
            return function (levels) {
                if (levels == 0) {
                    return undefined;
                }

                var result = [];
                var count = 5;
                for (var i = 0; i < count; i++) {
                    var expandable = Math.random() < 0.7;

                    result.push({
                        label: "Group\u00a0" + (++number),
                        expandable: expandable,
                        weight: (expandable ? 5 : 1) * Math.random(),
                        groups: generate(levels - 1)
                    });
                }
                return result;
            }
        })();

        var generateFromBackend = (function () {
            // @ts-ignore
            return async function (tag) {
                var url = tag === '' ? 'https://www.stackoverflow-tags.com/popular_tags.json' : ('https://www.stackoverflow-tags.com/tags/' + tag.replace(/#/g, 'sharp') + '.json');
                var request_result = (await axios.get(url)).data;
                var result: { label: any; expandable: boolean; weight: any; groups: any; }[] = [];
                request_result.shift()
                request_result.forEach(function (item: any, index: any) {
                    result.push({
                        label: item.tag2,
                        expandable: true,
                        weight: item.sum_matching_total,
                        groups: undefined
                    });
                });
                return result;
            }
        })();

        const generatedGroups = await generateFromBackend(props.search);
        console.log({generatedGroups});

        // @ts-ignore
        foamtree = new FoamTree({
            element: element,
            // Remove restriction on the minimum group diameter, so that
            // we can render as many diagram levels as possible.
            groupMinDiameter: 0,

            // Lower the minimum label font size a bit to show more labels.
            groupLabelMinFontSize: 3,

            // Disable rounded corners, deeply-nested groups
            // will look much better and render faster.
            groupBorderRadius: 0,

            // Lower the parent group opacity, so that lower-level groups show through.
            parentFillOpacity: 1,

            // Lower the border radius a bit to fit more groups.
            groupBorderWidth: 2,
            groupInsetWidth: 3,
            groupSelectionOutlineWidth: 0,
            groupBorderWidthScaling: 0.25,

            // Use a simple fading animation
            rolloutDuration: 0,
            pullbackDuration: 0,
            fadeDuraton: 0,

            openCloseDuration: 0,

            // When the user holds the mouse button over a group,
            // load the data if needed.
            // @ts-ignore
            /* onGroupHold: function (e) {
                if (!e.secondary && e.group.expandable && !e.group.groups) {
                    loader.load(e.group);
                } else {
                    this.open({ groups: e.group, open: !e.secondary });
                }
            }, */

            // Dynamic loading of groups does not play very well with expose.
            // Therefore, when the user double-clicks a group, initiate data loading
            // if needed and zoom in to the group.
            // @ts-ignore
            onGroupDoubleClick: function (e) {
                // e.preventDefault();
                try {
                    props.setCurrentTag(capitalizeFirstLetter(e.group.label));
                } catch(e) {
                    console.log('...');
                }
                var group = e.secondary ? e.bottommostOpenGroup : e.topmostClosedGroup;
                var toZoom: any;
                if (group) {
                    toZoom = e.secondary ? group.parent : group;
                    // Open on left-click, close on right-click
                    if (!e.secondary && group.expandable && !e.group.groups) {
                        loader.load(group, () => {
                            this.zoom(toZoom);
                        });
                    } else {
                        this.open({ groups: group, open: !e.secondary });
                        this.zoom(toZoom);
                    }
                } else {
                    toZoom = this.get("dataObject");
                    this.zoom(toZoom);
                }
            },

            // Display a "+" if a group can be expanded
            // @ts-ignore
            groupLabelDecorator: function (opts, params, vars) {
                /* if (params.group.expandable && !params.group.groups) {
                    vars.labelText += "\u00a0\u00bb";
                }
                if (params.group.groups && params.browseable === false) {
                    vars.labelText += "\u00a0\u00bb\u00bb";
                } */
            },

            dataObject: {
                groups: generatedGroups
            }
        });

        //
        // A simple utility for starting and stopping spinner animations
        // inside groups to show that some content is loading.
        //
        var spinner = (function(foamtree3) {
            // Set up a groupContentDecorator that draws the loading spinner
            // @ts-ignore
            foamtree3.set("wireframeContentDecorationDrawing", "always");
            // @ts-ignore
            foamtree3.set("groupContentDecoratorTriggering", "onSurfaceDirty");
            // @ts-ignore
            foamtree3.set("groupContentDecorator", function (opts, props, vars) {
                var group = props.group;
                if (!group.loading) {
                    return;
                }

                // Draw the spinner animation

                // The center of the polygon
                var cx = props.polygonCenterX;
                var cy = props.polygonCenterY;

                // Drawing context
                var ctx = props.context;

                // We'll advance the animation based on the current time
                var now = Date.now();

                // Some simple fade-in of the spinner
                var baseAlpha = 0.3;
                if (now - group.loadingStartTime < 500) {
                    baseAlpha *= Math.pow((now - group.loadingStartTime) / 500, 2);
                }

                // If polygon changed, recompute the radius of the spinner
                if (props.shapeDirty || group.spinnerRadius == undefined) {
                    // If group's polygon changed, recompute the radius of the inscribed polygon.
                    group.spinnerRadius = FoamTree.geometry.circleInPolygon(props.polygon, cx, cy) * 0.4;
                }

                // Draw the spinner
                var angle = 2 * Math.PI * (now % 1000) / 1000;
                ctx.globalAlpha = baseAlpha;
                ctx.beginPath();
                ctx.arc(cx, cy, group.spinnerRadius, angle, angle + Math.PI / 5, true);
                ctx.strokeStyle = "black";
                ctx.lineWidth = group.spinnerRadius * 0.3;
                ctx.stroke();

                // Schedule the group for redrawing
                // @ts-ignore
                foamtree3.redraw(true, group);
            });

            return {
                // @ts-ignore
                start: function (group) {
                    group.loading = true;
                    group.loadingStartTime = Date.now();

                    // Initiate the spinner animation
                    // @ts-ignore
                    foamtree3.redraw(true, group);
                },

                // @ts-ignore
                stop: function (group) {
                    group.loading = false;
                }
            };
            // @ts-ignore
        })(foamtree);

        //
        // A simple utility for simulating the Ajax loading of data
        // and updating FoamTree with the newly loaded data.
        //
        var loader = (function(foamtree2) {
            return {
                // @ts-ignore
                load: function (group, callback) {
                    console.log({ group });
                    if (!group.groups && !group.loading) {
                        // spinner.start(group);

                        generateFromBackend(group.label).then(results => {
                            group.groups = results;

                            // With hierarchical stacking, children will be added automatically
                            // when the group opens.
                            // @ts-ignore
                            foamtree2.open({ groups: group, open: true }).then(function() {
                                // Stop the spinner when the opening animation completes.
                                // spinner.stop(group);
                                callback();
                            });
                        })
                    }
                }
            };
            // @ts-ignore
        })(foamtree);

        // Lazy loading can be applied both in hierarchical and flattened
        // layouts, but the implementation differs slightly. See the references
        // to the variable below for the differences.
        var flattenedStacking = false;

        if (flattenedStacking) {
            // In most cases, with flattened stacking we'd need FoamTree to
            // draw more group levels than by default.
            // @ts-ignore
            foamtree.set({
                stacking: "flattened",
                maxGroupLabelLevelsDrawn: 20,
                maxGroupLevelsDrawn: 20,
            });
        }

        // Generate and set some initial data
        /* generateFromBackend().then(result => {
            // @ts-ignore
            foamtree.set({
                dataObject: {
                    groups: result
                }
            });
        }) */
    })(); });

    // @ts-ignore
    return (
        <div id="foam-tree-element-parent">
            <div id="foam-tree-element" style={{height: "calc(100vh - 64px)", width: "100vw"}}/>
        </div>
    );
});
