import React, { useState, useEffect } from 'react';
import axios from "axios";

export default function(props: { setCurrentTag: any, search: any, setSearch: any }) {
    const generateFromBackend = (function () {
        // @ts-ignore
        return async function (tag) {
            document.getElementById('graphSvgContainer')!.innerHTML = '<svg id="graphSvg"/>';

            let graphdata: {nodes: { id: string, group: number }[], links: { source: string, target: string, value: number }[]} = {
                nodes: [],
                links: []
            };
            const url = tag === '' ? '' : ('https://www.stackoverflow-tags.com/tags_relations_graph/' + tag.replace(/#/g, 'sharp') + '.json');
            const request_result = (await axios.get(url)).data;
            request_result.shift();
            request_result.forEach(function (item: any, index: any) {
                graphdata.nodes.push({
                    id: item.tag2,
                    group: 1
                });
                if (item.linkedTags) {
                    for (let linkedTag of item.linkedTags) {
                        graphdata.links.push({
                            source: item.tag2,
                            target: linkedTag,
                            value: 10
                        });
                    }
                }
            });
            console.log(graphdata);
            return graphdata;
        }
    })();

    useEffect(() => {
        props.setCurrentTag('Javascript');
        props.setSearch('javascript');
    }, []);

    useEffect(() => {
        generateFromBackend(props.search).then((graphdata) => {
            function dragstarted(d: any) {
                if (!d3v4.event.active) simulation.alphaTarget(0.3).restart();
                d.fx = d.x;
                d.fy = d.y;
            }

            function dragged(d: any) {
                d.fx = d3v4.event.x;
                d.fy = d3v4.event.y;
            }

            function dragended(d: any) {
                if (!d3v4.event.active) simulation.alphaTarget(0);
                d.fx = null;
                d.fy = null;
            }

            // @ts-ignore
            let d3v4: any = window.d3v4;
            document.getElementById('graphSvg')!.setAttribute('width', String(window.innerWidth));
            document.getElementById('graphSvg')!.setAttribute('height', String(window.innerHeight - 90));

            function handleZoom() {
                console.log('trying to zoom');
                console.log(d3v4.event);
            }

            let svg = d3v4.select("#graphSvg"),
                width = window.innerWidth,
                height = window.innerHeight - 90;

            let color = d3v4.scaleOrdinal(d3v4.schemeCategory20);

            let link = svg.append("g")
                .attr("class", "links")
                .selectAll("line")
                .data(graphdata.links)
                .enter().append("line")
                //.attr("stroke-width", function(d: any) { return Math.sqrt(d.value); });
                .attr("stroke-width", function(d: any) { return 1 });

            let simulation = d3v4.forceSimulation()
                .force("link", d3v4.forceLink().id(function(d: any) { return d.id; }))
                .force("charge", d3v4.forceManyBody().strength(-600))
                // .force("collide", d3v4.forceCollide(100).iterations(10))
                .force("center", d3v4.forceCenter(width / 2, height / 2));

            let node = svg.append("g")
                .attr("class", "nodes")
                .selectAll("g")
                .data(graphdata.nodes)
                .enter().append("g")

            let circles = node.append("circle")
                .attr("r", function(d: any) { return Math.ceil((graphdata.links.filter(l => l.source === d.id).length + graphdata.links.filter(l => l.target === d.id).length)/10) })
                .attr("fill", function(d: any) { return color(d.group); });

            // Create a drag handler and append it to the node object instead
            let drag_handler = d3v4.drag()
                .on("start", dragstarted)
                .on("drag", dragged)
                .on("end", dragended);

            drag_handler(node);

            let labels = node.append("text")
                .text(function(d: any) {
                    return d.id;
                })
                .attr('x', 6)
                .attr('y', 3);

            node.append("title")
                .text(function(d: any) { return d.id; });

            simulation
                .nodes(graphdata.nodes)
                .on("tick", ticked);

            simulation.force("link")
                .links(graphdata.links);

            function ticked() {
                link
                    .attr("x1", function(d: any) { return d.source.x; })
                    .attr("y1", function(d: any) { return d.source.y; })
                    .attr("x2", function(d: any) { return d.target.x; })
                    .attr("y2", function(d: any) { return d.target.y; });

                node
                    .attr("transform", function(d: any) {
                        return "translate(" + d.x + "," + d.y + ")";
                    })
            }
        });
    }, [props.search]);

    return <div id="graphSvgContainer">
        <svg id="graphSvg"/>
    </div>;
}
