import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";

import BarChart from "./BarChart";
import Typography from "@mui/material/Typography";

const BarchartWrapper = (props: { setCurrentTag: Function, setSearch: Function, data: any, limit: number; }) => {
  const targetRef = useRef(null);

  useEffect(() => {
    const bh = BarChart(targetRef.current, props.setCurrentTag, props.setSearch, props.limit);
    // @ts-ignore
    bh.update(props.data);
  }, [props.data]); // Run only once

  return (
    <div className="BarchartWrapper">
      <Typography fontStyle="italic" fontSize="0.7rem" textAlign="center">Number of occurrences</Typography>
      <div ref={targetRef}></div>
    </div>
  );
};

BarchartWrapper.propTypes = {
  data: PropTypes.object.isRequired,
};

export default BarchartWrapper;
