import Typography from '@mui/material/Typography';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

export default function() {
    return <div style={{
        backgroundImage: "url(https://www.stackoverflow-tags.com/background.jpg)",
        backgroundSize: "cover",
        width: '100vw',
        height: '100%',
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
    }}>
        <Typography variant="h1" align="center" style={{ fontWeight: "bold" }}>Stack Overflow tags</Typography>
        <Typography variant="h3" align="center" style={{ fontWeight: "bold" }}>Description</Typography>
        <Typography align="justify" style={{ padding: 20 }}>
            Stackoverflow est un site proposant des questions et réponses sur un large choix de thèmes, en particulier ceux concernant la programmation informatique. Il s’agit de l’un des sites les plus utilisés par les ingénieurs en informatique, et permet souvent de se renseigner très rapidement sur des bugs dans tous les langages et technologies connus.
            <br/>
            <br/>
            Comme ce site regroupe une très grande quantité de données concernant différents langages et technologies, il est possible d’en tirer plusieurs tendances par rapport aux technologies utilisées, ou du moins celles qui génèrent de nombreuses questions et réponses. Ainsi, en agrégeant ces données, nous pouvons réaliser une carte reliant ces technologies, et leur poids respectifs, entre elles.
            <br/>
            <br/>
            Ce qui nous intéresse dans le cadre de ce projet, ce sont les tags cités dans les questions de Stackoverflow. Un tag peut représenter un langage ou une technologie.
            <br/>
            <br/>
            Cela peut permettre différents usages : par exemple, un ingénieur qui s’intéresse au Python peut consulter la carte pour voir les langages et technologies qui sont le plus souvent utilisées avec Python (par exemple, django ou pandas), et se renseigner sur ces technologies pour approfondir ses connaissances. Un chef de projet peut également évaluer la probabilité que des ingénieurs connaissent une technologie en fonction de comment elle est représentée sur la carte.
            <br/>
            <br/>
            Ce projet a également un but ludique : il est possible de parcourir la carte pour découvrir de nouvelles technologies, ou de sonder l’état de l’art des technologies utilisées, par rapport à leur représentation sur la carte.
        </Typography>
        <Typography variant="h3" align="center" style={{ fontWeight: "bold" }}>Explorer les données</Typography>
        <div style={{ marginTop: 25, marginBottom: 25, marginLeft: "calc(50vw - 450px)" }}>
            <Link className="homeLink" to="/graph">Graph View</Link>
            <Link className="homeLink" to="/foamtree">FoamTree View</Link>
            <Link className="homeLink" to="/barplot">Histogram View</Link>
        </div>
    </div>;
}
