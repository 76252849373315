import * as React from 'react';
import ReactDOM from 'react-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/styles';
import App from './App';
import theme from './theme';

document.addEventListener("DOMContentLoaded", function(event) {
    ReactDOM.render(
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <App />
        </ThemeProvider>,
        document.querySelector('#root'),
    );
});
